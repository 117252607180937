import { animate, state, style, transition, trigger } from '@angular/animations';
import { AnimationTriggerMetadata } from '@angular/animations';

export const NGAnimations = {
  SlideRight: (): AnimationTriggerMetadata =>
    trigger('slideRight', [
      transition(':enter', [
        style({ opacity: '0', transform: 'translateX(-2rem)' }),
        animate('0.5s', style({ opacity: '1', transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: '1', transform: 'translateX(0)', position: 'absolute' }),
        animate('0.3s', style({ opacity: '0', transform: 'translateX(-2rem)' })),
      ]),
    ]),
  DetailExpand: () =>
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  GrowUp: (): AnimationTriggerMetadata =>
    trigger('growUp', [
      transition(':enter', [
        style({ flex: '0', opacity: '0', transform: 'scale(0)' }),
        animate('0.3s', style({ flex: '1', opacity: '1', transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ flex: '1', opacity: '1', transform: 'scale(1)' }),
        animate('0.5s', style({ flex: '0', opacity: '0', transform: 'scale(0)' })),
      ]),
    ]),
  SlideUp: (inTime = 0.3, outTime = 0.5): AnimationTriggerMetadata =>
    trigger('slideUp', [
      transition(':enter', [
        style({ opacity: '0', transform: 'translateY(5rem)' }),
        animate(`${inTime}s ease-in`, style({ opacity: '1', transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: '1', transform: 'translateY(0)', position: 'absolute' }),
        animate(`${outTime}s`, style({ opacity: '0', transform: 'translateY(5rem)' })),
      ]),
    ]),
  FadeIn: (): AnimationTriggerMetadata =>
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0', transform: 'translateY(1rem)' }),
        animate('0.3s', style({ opacity: '1', transform: 'translateY(0)' })),
      ]),
    ]),
  FadeOut: (): AnimationTriggerMetadata =>
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1', transform: 'translateY(0)' }),
        animate('0.3s', style({ opacity: '0', transform: 'translateY(5rem)' })),
      ]),
    ]),
};
